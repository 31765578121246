<template>
  <div class="cancel" v-if="!isProcessing">
    <v-img class="cancel__logo" :src="require('@/assets/img/logo.svg')" contain />
    <h2 class="cancel__title">本当に退会しますか？</h2>
    <h3 class="cancel__subtitle">アカウントについて</h3>
    <ul class="cancel__warning">
      <li class="cancel__warning__text">一度退会すると情報の復元ができません。</li>
      <li class="cancel__warning__text">退会後、同じGoogleアカウントでアカウント登録した場合もこれまでのQRコード情報等は引き継がれません。</li>
    </ul>
    <v-btn class="cancel__btn" depressed @click="cancelMembership()">退会する</v-btn>
    <p class="cancel__back" @click="$router.push({ name: 'list' })">QRコードの一覧へ戻る</p>
    <p class="cancel__complement">
      退会するには再度ログインする必要があります。<br><!--
      -->退会ボタン押下後、Google認証ページへ遷移します。
    </p>
  </div>
</template>

<script>
export default {
  name: 'cancel',
  async mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    }
  },
  methods: {
    /**
     * 退会処理
     */
    async cancelMembership () {
      this.$store.commit('setSubmitting', true)

      // 退会処理情報をセッションに保存
      sessionStorage.setItem('cancelUID', this.uid)

      // Auth認証
      // 続きの処理はonAuthで実施
      this.$store.dispatch('auth/signin', 'google')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.cancel {
  width: 100vw;
  max-width: $max_width;
  padding: 0 $padding_width;
  margin: $header_height auto $footer_height auto;
  &__logo {
    width: auto;
    height: 20px;
    margin-top: 40px;
  }
  &__title {
    margin-top: 60px;
    font-size: 1.4rem;
    font-weight: normal;
    text-align: center;
  }
  &__subtitle {
    margin-top: 40px;
    font-size: 1rem;
    font-weight: bold;
  }
  &__warning {
    &__text {
      padding: 5px 0;
      font-size: 1.2rem;
    }
  }
  &__btn {
    display: block;
    margin: 60px auto 0 auto;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: auto;
      padding: 6px 15px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $red_color;
    }
  }
  &__back {
    margin-top: 10px;
    font-size: 1.2rem;
    color: $dark_gray_color;
    text-align: center;
  }
  &__complement {
    display: block;
    margin: 30px 0 0 0;
    font-size: 1rem;
    color: $dark_gray_color;
    text-align: center;
  }
}
</style>
